<template>
    <Layout>
      <div class="row">
        <div class="col-lg-12">
          <div class="card border border-radius-12">
           
            <div class="card-body table">
              <div class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2">
                <div class="d-flex align-items-center"> 
                  <PageHeader :title="title" class="poppins fw-normal ps-3 pt-3" />
                <div class=""> 
                  <searchInput @getSearched="handleSearch"/>
                </div>
                </div>
                
                <!-- <div :class="{
      'del_btn-wrapper': true,
      'border-orange rounded-pill p1': multipuleIds.length >= 1
    }">
  
    <button
      v-if="multipuleIds.length >= 1"
      @click="deleteBulk"
      class="
        btn
        border-0
        px-5
        fw-bold
        text-white
        btn btn-primary
        waves-effect waves-light
        w-md
        primary-button
        rounded-pill
      "
    >
      Delete All
    </button>
    </div> -->
              <div class="add_new border-orange rounded-pill p1">  <router-link to="/loation-category/add"
             class="
               btn
               btn-primary
               border-0
               px-5
               fw-bold
               text-white
               btn btn-primary
               waves-effect waves-light
               w-md
               primary-button
               rounded-pill
             "
           >
             Add New
              </router-link></div>
           </div>
              <!-- <div class="table-responsive">
                <table
                  id="datatable"
                  class="table table-centered table-nowrap mb-0"
                >
                  <thead class="table-light bg-transparent">
                    <tr>
                      <th style="width: 20px">
                        <div class="form-check font-size-16">
                          <input
                            @change="selectAllRecord"
                            v-model="selectAll"
                            type="checkbox"
                            class="form-check-input"
                            id="customCheck1"
                          />
                          <label class="form-check-label" for="customCheck1"
                            >&nbsp;</label
                          >
                        </div>
                      </th>
                      <th>Category Title</th>
                      <th>Category Slug</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr colspan="3" v-if="loading">
                      <td colspan="4">
                        <APILoader
                          :loading="loading"
                          class="align-middle text-center"
                        ></APILoader>
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(category, index) in categories"
                      :key="index"
                    >
                      <th style="width: 20px">
                        <div class="form-check font-size-16">
                          <input
                            v-model="multipuleIds"
                            :value="category.id"
                            type="checkbox"
                            class="form-check-input"
                            id="customCheck1"
                          />
                          <label class="form-check-label" for="customCheck1"
                            >&nbsp;</label
                          >
                        </div>
                      </th>
                      <td>
                        <span>{{ category.title }}</span>
                      </td>
                      <td>{{ category.slug }}</td>
  
                      <td class="table-icon">
                        <div class="d-flex">
                          <button
                            style="margin: 10px"
                            @click="updateCategory(category.id)"
                            class="p-0 bg-transparent border-0"
                          >
                            <span class="text-primary">
                              <i class="uil-edit"></i>
                            </span>
                          </button>
                          <button
                            @click="deleteCategory(category.id)"
                            class="p-0 bg-transparent border-0"
                          >
                            <span class="text-danger">
                              <i class="uil-trash-alt"></i>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <!-- end table-responsive -->
              <GeneralTable :isProcessing="processing" 
              :items="categories"  :fields="fields"
              @deleteItem="deletecategory"
              @editItem = "updateCategory"
               >
  
            </GeneralTable>
            <Pagination
            @paginator="allCategory"
            :pagination="paginatelinks"
            :showing="paginate"
          />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  <script>
  import Layout from "../../../layouts/static/main";
  import PageHeader from "@/components/static/page-header";
  import appConfig from "@/app.config";
  import Swal from "sweetalert2";
  import $ from "jquery";
  import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
  import searchInput from "../../../../components/general/searchInput.vue";
  import Pagination from "../../../../components/general/pagination.vue";
  export default {
    page: {
      meta: [
        {
          name: "add-user",
          content: appConfig.description,
        },
      ],
    },
    name: "category",
    components: {
      Layout,
      PageHeader,
      GeneralTable,
      searchInput,
      Pagination
    },
    data() {
      return {
        fields: [
          { key: 'select', label: 'Select', sortable: false, },  
          { key: 'id'},
          { key: 'avatar_with_title', label: 'Category', },
          { key: 'created_at',tdClass:'align-middle'},
          { key: 'updated_at', label: 'Last Updated',tdClass:'align-middle'},
          { key: 'action',tdClass:'align-middle'},
        ],
        multipuleIds: [],
        selectAll: false,
        title: "Category Listing",
        loading: true,
        categories: null,
        processing: false,
        paginate: {},
        paginatelinks: []
       
      };
    },
    mounted() {
      this.allCategory({});
      this.processing = true;
    },
    computed: {
      paginatedItems() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        return this.items.slice(start, start + this.itemsPerPage);
      }
    },
    methods: {
     
      deletecategory(id) {
        let apiUrl = `location-category/`;
             let confirmationMessage = "category deleted successfully."
             this.processing = true;
             this.deleteIt(apiUrl, id,confirmationMessage)
             .then(() => {
              this.processing = false;
              this.allCategory({});
              }).catch(error => {
                  console.error('Error deleting job:', error);
                  this.processing = false;
              });
        console.log('Received ID from child:', id);
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString(); 
  },
      randerData(response) {
        this.paginate = {
        first_page: response?.data?.data?.first_page_url,
        last_page: response?.data?.data?.last_page_url,
                links: response?.data?.data?.links,
                from: response?.data?.data?.from,
                to: response?.data?.data?.to,
                total: response?.data?.data?.total,
       }
       this.paginate.links = this.paginate?.links?.length > 0 ? this.paginate.links:[]
       this.paginatelinks=this.paginate.links.map(item=>({
                url: item.url,
                label: item.label,
                active: item.active,
              }));
  
        let categories = Array.isArray(response?.data?.data?.data) && response?.data?.data?.data?.length > 0
        ? response?.data?.data?.data
        :[];
        this.categories = categories.map(item=>({
          id: item.id,
          avatar: item.icon,
          name : item.title,
          created_at: this.formatDate(item.created_at), 
         updated_at: this.formatDate(item.updated_at),
  
        }));
        console.log(this.categories, "rrrrrrrr")
        this.processing = false;
  
      },
  
      allCategory({url = null, searchItem = null}) {
        this.processing = true;
        let endPoint = "location-category"
        endPoint = url ? url : endPoint;
        this.$axios
          .get(endPoint, {params:{search: searchItem}})
          .then((response) => {
            setInterval(this.randerData(response), 1500);
          })
          .catch((error) => {
            console.log(error.response);
            this.processing = false;
          });
      },
      handleSearch(searchItem) {
         this.allCategory({searchItem});
    },
      updateCategory: function (id) {
        this.$router.push({
          name: "update-location-category",
          params: { id: id },
        });
      },
      async delete(id) {
        try {
          const {
            data: { status },
          } = await this.$axios.delete("categories/" + id);
          this.responseDelete = status;
        } catch (error) {
          console.log(error);
        }
      },
      deleteCategory(id) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.delete(id);
            let status = this.responseDelete;
            if (status == true) {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.allCategory();
            }
          }
        });
      },
      selectAllRecord() {
        if (this.selectAll) {
          let ids = [];
          this.categories.forEach((element) => {
            ids.push(element.id);
          });
          this.multipuleIds = [...new Set(ids)];
          console.log(this.multipuleIds);
        } else {
          this.multipuleIds = [];
  
          console.log(this.multipuleIds);
        }
      },
      deleteBulk() {
        this.$axios
          .post("delete-category-bulk", { ids: this.multipuleIds })
          .then((response) => {
            this.allCategory();
            this.triggerSwal(response.data.message, "success");
            this.selectAll = false;
            this.multipuleIds = [];
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    },
  };
  </script>
  